<template>
    <v-container class="mt-5  mb-16">
      <p class="headline font-weight-medium pediidos--text mb-5 ">{{$t('user_profile')}}</p>
      <v-sheet  >
        <v-row >
          <v-col class="pb-0 " cols="12" md="6" >
            <v-card elevation="4">
              <v-card-title class="headline">{{$t('personal_data')}}</v-card-title>
                <v-form class=" pa-5 text-center" ref="form" v-model="val_profile" lazy-validation>
                  <v-avatar size="21vh"  class="mb-5" >
                    <v-img :src="profile.picture"></v-img>
                  </v-avatar>
                  <v-file-input v-if="edit_profile"
                    @change="previewAvatar($event)"
                    accept="image/png, image/jpeg"
                    :label="this.$t('avatar')"
                    counter
                    show-size
                    prepend-icon="mdi-camera"
                    :rules="rules.picture"
                    outlined
                    color="pediidos"
                  ></v-file-input>
                  <v-text-field
                    color="pediidos"
                    :label="this.$t('email')"
                    :placeholder="this.$t('email')"
                    v-model="profile.email"
                    disabled
                    prepend-icon="mdi-at"
                    outlined
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    color="pediidos"
                    :label="this.$t('name')"
                    :placeholder="this.$t('name')"
                    v-model="profile.name"
                    :rules="rules.name"
                    :disabled="!edit_profile"
                    prepend-icon="mdi-account"
                    autocomplete="off"
                    outlined
                    type="text"
                    :counter="( edit_profile ? 30 : false )"
                    maxlength="30"
                    required
                  ></v-text-field>
                  <v-text-field
                    color="pediidos"
                    :label="this.$t('phone')"
                    :placeholder="this.$t('phone')"
                    v-model="profile.phone"
                    :rules="rules.phone"
                    :disabled="!edit_profile"
                    prepend-icon="mdi-phone"
                    autocomplete="off"
                    outlined
                    type="phone"
                    :counter="( edit_profile ? 15 : false )"
                    maxlength="15"
                    required
                  ></v-text-field>
                  <v-text-field
                    color="pediidos"
                    :label="this.$t('dni')"
                    :placeholder="this.$t('dni')"
                    v-model="profile.identification"
                    :rules="rules.identification"
                    :disabled="!edit_profile"
                    prepend-icon="mdi-fingerprint"
                    autocomplete="off"
                    outlined
                    type="phone"
                    :counter="( edit_profile ? 15 : false )"
                    maxlength="15"
                    required
                  ></v-text-field>
                  <v-btn v-if="!edit_profile" outlined rounded color="pediidos" @click.stop="edit_profile = true" >{{$t('edit')}}</v-btn>
                  <div v-else>
                    <v-btn class="ma-2" :disabled="!val_profile" outlined rounded color="success" @click="updateProfile()" >{{$t('update')}}</v-btn>
                    <v-btn class="ma-2" outlined rounded color="error" @click="edit_profile = false, profile.picture = user.picture">{{$t('cancel')}}</v-btn>
                  </div>
                </v-form>
            </v-card>
          </v-col>
          <v-col class="pb-0 " cols="12" md="6" >
            <v-card elevation="4">
              <v-card-title class="headline">{{$t('shipping_data')}}</v-card-title>
              <v-form class=" pa-5 text-center" ref="form" v-model="val_profile" lazy-validation>
                <div class="d-flex flex-column justify-center align-center">
                  <v-img class="mb-5" :src="previewMap()"></v-img>
                </div>
                <v-textarea
                  color="pediidos"
                  :label="this.$t('address')"
                  :placeholder="this.$t('onboarding.address_placeholder')"
                  v-model="user.address"
                  disabled
                  prepend-icon="mdi-map-marker"
                  auto-grow
                  outlined
                  rows="3"
                  row-height="10"
                ></v-textarea>
                <v-text-field
                  color="pediidos"
                  :label="this.$t('city')"
                  :placeholder="this.$t('city')"
                  v-model="user.city"
                  disabled
                  prepend-icon="mdi-city-variant-outline"
                  outlined
                ></v-text-field>
                <v-text-field
                  color="pediidos"
                  :label="this.$t('detail')"
                  :placeholder="this.$t('onboarding.detail_placeholder')"
                  v-model="user.address_detail"
                  disabled
                  prepend-icon="mdi-comment-alert-outline"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="user.address_alias"
                  disabled
                  prepend-icon="mdi-tag"
                  :label="this.$t('alias')"
                  :placeholder="this.$t('onboarding.alias_placeholder')"
                  outlined
                  color="pediidos"
                ></v-text-field>
                <EditAddress id_map="map-profile" ></EditAddress>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
</template>

<script type="text/javascript">
  import EditAddress from '@/components/EditAddress.vue';
  import FileFirebase from '@/firebase/files.js';
  import Floppy from '@/helpers/IndexedDB.js';
  import Api from '@/helpers/api.js';
  import { mapMutations, mapState } from 'vuex';
  import * as constants from '@/constants';
  export default {
    name: 'profile',
    components: { EditAddress },
    data() {
      return {
        edit_profile: false,
        profile: {
            email: '',
            name: '',
            phone: '',
            identification: '',
            picture: '',
            picture_temp: null,
        },
        rules: {
          name: [v => !!v ||  this.$t('error.required_name'), v => (v && v.length <= 30 && v.length >= 5 ) || this.$t('error.length_name'),],
          phone: [v => !!v || this.$t('error.required_phone'),v => (v && v.length <= 15 && v.length >= 7 ) || this.$t('error.length_phone'),],
          identification: [v => !!v || this.$t('error.required_dni'),v => (v && v.length <= 15 && v.length >= 8 ) || this.$t('error.length_dni'),],
          picture:  [v => !v || v.size < 2000000 || this.$t('error.size_avatar'),],
        },
        val_profile: true,
      }
    },
    async mounted(){
      this.getProfile();
      this.getAddress();
    },
    methods: {
      ...mapMutations(['APP_LOADER','SET_USER']),
      async getProfile() {
        this.APP_LOADER(true);
        Api.post('users/profile/',{user_id: this.user.user_id} ).then((result)=> {
          if(result.status < 300) {
            this.user.name = result.data.user.first_name;
            this.user.username = result.data.user.email;
            this.user.email = result.data.user.email;
            this.user.user_id = result.data.user.id;
            this.user.status = result.data.status.name;
            this.user.picture = result.data.picture;
            this.user.identification_number = result.data.identification_number;
            this.user.phone = result.data.phone;
            this.user.firebase_uid = result.data.firebase_uid;
            this.user.type_user = result.data.type_user.name;
            Floppy.update('users', this.user, this.user.firebase_uid);
          }else{
            console.log('This is error ',result.detail);
          }
        }).catch((error)=> {
          console.log('This is error ',error);
        })
        setTimeout(()=>{ this.APP_LOADER(false); }, 500);
      },
      async getAddress() {
        this.APP_LOADER(true);
        Api.post('users/address/', {user_id:this.user.user_id}).then((result)=> {
          if(result.status < 300) {
            this.user.address_id = result.data.id;
            this.user.address_alias = result.data.address_alias;
            this.user.address_detail = result.data.address_detail;
            this.user.lat = result.data.lat;
            this.user.lon = result.data.lon;
            this.user.city = result.data.city;
            this.user.address = result.data.address;
            Floppy.update('users', this.user, this.user.firebase_uid);
            this.profile.email = this.user.email;
            this.profile.name = this.user.name;
            this.profile.phone = this.user.phone;
            this.profile.identification = this.user.identification_number;
            this.profile.picture = this.user.picture;
          }else{
            console.log('This is error ',result.detail);
          }
        }).catch((error)=> {
          console.log('This is error ',error);
        })
        setTimeout(()=>{ this.APP_LOADER(false); }, 500);
      },
      updateProfile(){
        let data = {
                user_id: this.user.user_id,
                name: this.profile.name,
                phone: this.profile.phone,
                identification_number: this.profile.identification,
            }
        Api.put('users/update/profile/', data).then((result)=> {
          if(result.status < 300) {
            if(this.profile.picture_temp !== null){
              this.uploadImage();
            }
            this.user.name = this.profile.name;
            this.user.phone = this.profile.phone;
            this.user.identification_number = this.profile.identification;
            Floppy.update('users',this.user, this.user.firebase_uid);
            this.edit_profile = false;
            this.myNotify('success',this.$t('_success'),this.$t('onboarding.msg_update_profile'));
          }else{
            this.myNotify('error',this.$t('_error'),this.$t('error.generic'));
          }
        }).catch((error)=> {
          console.log('This is error ',error);
        })
      },
      uploadImage(){
        let imageFile = this.profile.picture_temp;
        return new Promise(async(resolve,reject)=>{
          try{
            var extension = imageFile['type'].split('/')[1];
            var name = 'profile-'+this.user.user_id+'.'+extension;
            const url = await FileFirebase.uploadFile(imageFile,'misitio/pictures_profiles/',name);
            let data = {"user_id": this.user.user_id,"image": url}
            const result = await Api.put('users/update/profile/',data);
            this.profile.picture_temp = null;
            this.user.picture = url;
            await Floppy.update('users',{picture:url}, this.user.firebase_uid);
            resolve(true)
          }catch(error) {
            console.log('Error ',error);
            reject(false)
          }
        })
      },
      previewAvatar(e) {
        if(e == null){
          this.profile.picture = this.user.picture;
          this.profile.picture_temp = null;
        }else{
          this.profile.picture = URL.createObjectURL(e);
          this.profile.picture_temp = e;
        }
      },
      previewMap() {
        // TODO: signature URL
        // https://cloud.google.com/blog/products/maps-platform/google-maps-platform-best-practices-securing-api-keys-when-using-static-maps-and-street-view-apis
        // https://developers.google.com/maps/documentation/streetview/get-api-key?_ga=2.229844700.-158386310.1622133674#sample-code-for-url-signing
        let lat = this.user.lat;
        let lon = this.user.lon;
        let key = constants.googleMapsApiKey;
        return `https://maps.googleapis.com/maps/api/staticmap?
                center=${lat},${lon}
                &zoom=16
                &size=600x257
                &maptype=terrain
                &markers=color:red|${lat},${lon}
                &key=${key}`
      },
    },
    computed: {
      ...mapState(['appLoader','user']),
    },
  }
</script>

<style type="text/css" scoped>
</style>